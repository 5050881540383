/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'
import { Link } from 'gatsby'
class Selectclients extends React.Component {
  render() {
    return (
      <div className="Selectclients content-wrap ">
        <Container>
          <Row className="introduction sp-10">
            <Col md="8" lg="7" xl="6">
              <h1>Select clients.</h1>
              <p>
                Our clients treat us like partners because we consistently show
                that our priority is getting results for their business more
                than billing hours for us.
              </p>
            </Col>
          </Row>
          <Row id="clients">
            <Col lg="4" md="6">
              <h2 className="h1">Babylonstoren</h2>
              <p>A multi-year relationship tasked with the strategy, design, development and operation of a world class end-to-end e-commerce project. This included us hiring their internal team of designers and developers.</p>
              {/*
              <Link className="styled__Link-lfEBUk iUBhrC" to="/work/babylonstoren">
                See case study
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
              */}
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Barclays Africa</h2>
              <p>When Barclays sold Barclays Africa we were tasked with the strategy, staff research and design of a digital platform to make sure 40,000 of their staff across 12 countries transitioned well.</p>
            </Col>

            <Col lg="4" md="6">
              <h2 className="h1">Diageo Trust South Africa</h2>
              <p>We overhauled the entire startup incubation program from end to end with a focus on profitable startups using technology for social good.</p>
              <Link className="styled__Link-lfEBUk iUBhrC" to="/STSC/">
                See case study
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Distell</h2>
              <p>Various design thinking, product strategy and rapid prototyping lead digital transformation across divisions tackling both internal and consumer facing challenges.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Investec</h2>
              <p>The product strategy, design and front-end UX development of what became Investec’s One Place online self-service platform.</p>
              {/*
              <Link className="styled__Link-lfEBUk iUBhrC" to="/work/investec">
                See case study
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
              */}
            </Col>

            <Col lg="4" md="6">
              <h2 className="h1">SantaShoebox</h2>
              <p>System architecture, design and development of the platform consisting of the consumer facing experience, volunteer admin portal and logistics system linking kids to their parcel donor and tracking the delivery of over 1 million boxes.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Sanlam</h2>
              <p>Expanding the brand beyond marketing into digital products with various consumer and intermediary facing product strategy and prototyping sprints.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Sanlam Private Wealth</h2>
              <p>Ongoing digital transformation through the creation of a Design System used to deliver the new Sanlam Private Wealth brand website as well as a client service tool for portfolio managers.</p>
              {/*
              <Link className="styled__Link-lfEBUk iUBhrC" to="/work/spw">
                See case study
                <svg
                  className="styled__Arrow-fdeFVt fRPwvC"
                  width="14"
                  height="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#030303" fill="none" fillRule="evenodd">
                    <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                    <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                  </g>
                </svg>
              </Link>
              */}
            </Col>

            <Col lg="4" md="6">
              <h2 className="h1">Santam</h2>
              <p>We’ve been helping this 100 year old insurance giant find new ways to deliver insurance for consumers and brokers by testing various product strategies through rapid prototyping sessions with end customers.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Stor-Age</h2>
              <p>Product design and development of the paperless self-service onboarding service requiring a consumer facing onboarding experience as well as the internal staff portal.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Standard Bank Life Insurance</h2>
              <p>Ongoing transformation of the self-service customer-facing and employee-facing products, enabled by launching their first ever insurance API leading to a 150% increase in insurance leads.</p>
            </Col>
            <Col lg="4" md="6">
              <h2 className="h1">Vital Health Foods</h2>
              <p>A multi-year digital transformation lead by the end-to-end rollout of e-commerce solutions across the groups 3 brands including logistics as well as various digital product innovations. This lead to a 136% increase in online revenue.</p>
            </Col>
          </Row>
          <Row className="spb-10">
            <Col className="testimonial">
              <h2>
                The secret to their success is their ability to assemble
                talented teams of people that care. I think of Urbian as a
                critical member of my team, not some outsourced function.
              </h2>
              <span className="attribution">
                Keith Elliott, CEO Of Met-S Care
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Selectclients
